import dataTypes from "../actions/types/dataTypes";

const { GET_DATA_COUNT, GET_DATA_COUNT_SUCCESS, GET_DATA_COUNT_FAIL } =
  dataTypes;

const intialState = {
  from_ts: "",
  to_ts: "",
  past_from_ts: "",
  past_to_ts: "",
  data_count: [],
  past_data_count: [],
  loading: false,
  graph: {},
  fetchCount: 0,
};

const dataReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_DATA_COUNT:
      return {
        ...state,
        from_ts: "",
        to_ts: "",
        past_from_ts: "",
        past_to_ts: "",
        data_count: [],
        past_data_count: [],
        loading: true,
      };
    case GET_DATA_COUNT_SUCCESS:

      console.log('GET_DATA_COUNT_SUCCESS action.payload: ', action.payload);

      return {
        ...state,
        from_ts: action.payload.from_ts,
        to_ts: action.payload.to_ts,
        past_from_ts: action.payload.past_from_ts,
        past_to_ts: action.payload.past_to_ts,
        data_count: action.payload.current.results,
        past_data_count: action.payload.past.results,
        // graph: action.payload.results.graph,
        loading: false,
        fetchCount: state.fetchCount + 1,
      };
    case GET_DATA_COUNT_FAIL:
      return {
        ...state,
        from_ts: "",
        to_ts: "",
        past_from_ts: "",
        past_to_ts: "",
        data_count: [],
        past_data_count: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default dataReducer;
