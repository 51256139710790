import moment from "moment";
import api from "../../config/api";
import config from "../../config/config";
import dataTypes from "./types/dataTypes";

const { GET_DAILY_DATA, GET_DAILY_MIN_DATA  , GET_DAILY_MIN_PORTOFOLIO} = config.api_endpoint;

const { GET_DATA_COUNT, GET_DATA_COUNT_SUCCESS, GET_DATA_COUNT_FAIL } =
  dataTypes;

const fetchingDataCount = () => {
  return {
    type: GET_DATA_COUNT,
  };
};

const fetchingDataCountSuccess = (data) => {
  return {
    type: GET_DATA_COUNT_SUCCESS,
    payload: data,
  };
};

const fetchingDataCountFail = () => {
  return {
    type: GET_DATA_COUNT_FAIL,
  };
};

const getCurrentSite = config.getCurrentSite;

export const fetchDataCount = (from_ts, to_ts, siteID = []) => {
  const getFormattedSiteIds = (siteIDs) => {
    if (Array.isArray(siteIDs) && siteIDs.length > 0) {
      return siteIDs.map((id) => `site_id=${id}`).join("&"); // Join multiple site IDs
    }
    return `site_id=${getCurrentSite()}`; // Fallback to current site
  };

  const past_from_ts = moment(from_ts, "YYYY-MM-DD").subtract(1, "years").format("YYYY-MM-DD");
  const past_to_ts = moment(to_ts, "YYYY-MM-DD").subtract(1, "years").format("YYYY-MM-DD");

  return (dispatch) => {
    dispatch(fetchingDataCount());

    const siteIdsQuery = getFormattedSiteIds(siteID);

    // API call helper function
    const fetchData = (from_ts, to_ts) => {
      return api.get(`${GET_DAILY_MIN_DATA}?from_ts=${from_ts}&to_ts=${to_ts}&${siteIdsQuery}`);
    };

    // Fetch current and past data concurrently
    Promise.all([fetchData(from_ts, to_ts), fetchData(past_from_ts, past_to_ts)])
      .then(([currentRes, pastRes]) => {
        if (currentRes.status === "Success" && pastRes.status === "Success") {
          dispatch(
            fetchingDataCountSuccess({
              from_ts,
              to_ts,
              past_from_ts,
              past_to_ts,
              current: currentRes,
              past: pastRes,
            })
          );
        } else {
          dispatch(fetchingDataCountFail());
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        dispatch(fetchingDataCountFail());
      });
  };
};



export const fetchDataCountPorto = (from_ts, to_ts, siteID = []) => {
  const getFormattedSiteIds = (siteIDs) => {
    if (Array.isArray(siteIDs) && siteIDs.length > 0) {
      return siteIDs.map((id) => `site_id=${id}`).join("&"); // Join multiple site IDs
    }
    return `site_id=${getCurrentSite()}`; // Fallback to current site
  };

  const past_from_ts = moment(from_ts, "YYYY-MM-DD").subtract(1, "years").format("YYYY-MM-DD");
  const past_to_ts = moment(to_ts, "YYYY-MM-DD").subtract(1, "years").format("YYYY-MM-DD");

  return (dispatch) => {
    dispatch(fetchingDataCount());

    const siteIdsQuery = getFormattedSiteIds(siteID);

    // API call helper function
    const fetchData = (from_ts, to_ts) => {
      return api.get(`${GET_DAILY_MIN_PORTOFOLIO}?from_ts=${from_ts}&to_ts=${to_ts}&${siteIdsQuery}`);
    };

    // Fetch current and past data concurrently
    Promise.all([fetchData(from_ts, to_ts), fetchData(past_from_ts, past_to_ts)])
      .then(([currentRes, pastRes]) => {
        if (currentRes.status === "Success" && pastRes.status === "Success") {
          dispatch(
            fetchingDataCountSuccess({
              from_ts,
              to_ts,
              past_from_ts,
              past_to_ts,
              current: currentRes,
              past: pastRes,
            })
          );
        } else {
          dispatch(fetchingDataCountFail());
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        dispatch(fetchingDataCountFail());
      });
  };
};