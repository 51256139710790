import { Row, Col, Modal, Image } from "react-bootstrap";
// icons
import CubeIcon from "../assets/images/live/cube.svg";
import CubeDarkIcon from "../assets/images/live/cube-dark.svg";

function SelectWaterModal({
  title = "Select",
  show,
  onHide,
  spaceBlocks = [],
  allChosenSpaces = [],
  selectedBlock,
  onBlockClicked,
  onBlockMouseOver,
  onBlockMouseLeave,
  ...props
}) {
  let theme = localStorage.getItem("theme");

  const SpaceDialogContent = () => {
    return (
      <>
        <Row style={{ margin: "10px" }}>
          {spaceBlocks !== undefined ? (
            spaceBlocks.map((_, index) => {
              return (
                <Col md="3" sm="6" className="spacer col-6" key={index}>
                  <div
                    className={`rounded space-floor-blk ${
                      selectedBlock === _.name &&
                      allChosenSpaces.indexOf(selectedBlock) < 0 &&
                      "active"
                    } ${allChosenSpaces.indexOf(_.name) > -1 && "disabled"}`}
                    disabled={
                      allChosenSpaces.indexOf(_.name) > -1 ? "disabled" : ""
                    }
                    onClick={onBlockClicked}
                    onMouseOver={onBlockMouseOver}
                    onMouseLeave={onBlockMouseLeave}
                    data-id={_.name}
                  >
                    <Image
                      className="img-fluid"
                      onMouseOver={onBlockMouseOver}
                      data-id={_.name}
                      src={
                        theme === "light" &&
                        selectedBlock === _.name &&
                        allChosenSpaces.indexOf(selectedBlock) < 0
                          ? CubeDarkIcon
                          : theme === "light" && selectedBlock !== _.name
                          ? CubeIcon
                          : CubeIcon
                      }
                    />
                    <p
                      className="space-blk-level"
                      style={{
                        paddingLeft: "10px",
                        display: "inline-block",
                        lineHeight: "0.5em",
                      }}
                    >
                      <b>{_.level}</b>
                    </p>
                    <p
                      className="space-blk-name"
                      data-id={_.name}
                      style={{ lineHeight: "1em", textAlign: "left" }}
                    >
                      {_.name}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <div></div>
          )}
        </Row>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        closeButton
        className={`${theme === "light" ? "" : "dark dark-blk"}`}
      >
        <Modal.Title>
          <div className="modal-padding-blk"></div>{" "}
          <div className="modal-title-blk">
            <span className={`${theme === "light" ? "" : "dark"}`}>
              {title}
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
        <SpaceDialogContent />
      </Modal.Body>
    </Modal>
  );
}
export default SelectWaterModal;
