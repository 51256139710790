import React from "react";
import {
  Col,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import * as wjCore from "wijmo/wijmo";

// views
import BlankBlk from "./blankBlk";

// image & css
import IndicatorDark from "../assets/images/live/indicator-alt.png";
import Indicator from "../assets/images/live/indicator.png";
import Meter from "../assets/images/live/meter-new.svg";
import Power from "../assets/images/live/power.png";
import SpaceDarkIcon from "../assets/images/live/space-dark.svg";
import SpaceIcon from "../assets/images/live/space.svg";
import TemperatureDark from "../assets/images/live/temperature-dark.png";
import Temperature from "../assets/images/live/temperature.png";
import WaterDark from "../assets/images/live/water-dark.png";
import Water from "../assets/images/live/water.png";
import "./liveStatsBlkMulti.css";

function statsBlkMulti(props) {
  const theme = localStorage.getItem("theme");
  const heading = props.heading;
  const data = props.data;
  const water_data = props.water_data;

  // add custom css for dark theme tooltip
  if (theme == "dark") {
    var styles = `
      .tooltip-arrow::before, .tooltip-arrow::before {
        border-bottom-color: #C4C4C4 !important;
      }
    `;

    var styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }

  const showLiveValueModal = props.showLiveValueModal;
  const setShowLiveValueModal = props.setShowLiveValueModal;
  const showLiveType = props.showLiveType;
  const setShowLiveType = props.setShowLiveType;
  const showMoreIconClicked = props.showMoreIconClicked;
  const showSpaceDialogClicked = props.showSpaceDialogClicked;
  const hideKaerAir = props.hideKaerAir || false;
  const onChilledWaterSupplyClicked = props.onChilledWaterSupplyClicked;
  const hideChilledWater = props.hideChilledWater || false;
  const hideKaerWater = props.hideKaerWater || false;
  const shiftOnDesktopView =
    props.hideChilledWater || props.hideKaerAir ? true : false;
  const removeWidgetClicked = props.removeWidgetClicked;
  const onMoveWidget = props.onMoveWidget || (() => {});

  const showNumberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };

  let dragSource = null;
  let dropTarget = null;

  const dashboardDragStart = (e) => {
    const target = e.target;
    console.log("target:", target);
    if (target) {
      dragSource = target;
      wjCore.addClass(dragSource, "drag-source");
      const dt = e.dataTransfer;
      dt.effectAllowed = "move";
      dt.setData("text", dragSource.innerHTML);
    }
  };

  const dashboardDragOver = (e) => {
    if (dragSource) {
      let tile = wjCore.closest(e.target, ".space-stats-wrapper");
      if (tile === dragSource) {
        tile = null;
      }
      if (dragSource && tile && tile !== dragSource) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }
      if (dropTarget !== tile) {
        wjCore.removeClass(dropTarget, "drag-over");
        dropTarget = tile;
        wjCore.addClass(dropTarget, "drag-over");
      }
    }
  };

  const dashboardDrop = (e) => {
    if (dragSource && dropTarget) {
      // finish drag/drop
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();

      const srcId = dragSource.getAttribute("data-id");
      const dstId = dropTarget.getAttribute("data-id");

      onMoveWidget(srcId, dstId);

      var clone = dropTarget.cloneNode(true);
      clone.classList.remove("drag-over");

      wjCore.removeClass(dragSource, "drag-source");
      wjCore.removeClass(dropTarget, "drag-over");
    }
  };

  const dashboardDragEnd = () => {
    wjCore.removeClass(dragSource, "drag-source");
    wjCore.removeClass(dropTarget, "drag-over");

    dragSource = dropTarget = null;
  };

  return (
    <>
      <Row
        className={`rounded px-4 py-4 mb-4 stats-blk-multi${
          theme === "light" ? "" : "-dark"
        }`}
      >
        <Modal
          show={showLiveValueModal}
          className=""
          id="liveStatsBlkModal"
          onHide={function () {
            setShowLiveValueModal(false);
          }}
        >
          <Modal.Header
            closeButton
            className={`${theme === "light" ? "" : "dark dark-blk"}`}
          >
            <Modal.Title>
              <div className="">
                <span
                  className={`liveModalTitle ${
                    theme === "light" ? "" : "dark"
                  }`}
                >
                  {showLiveType === "water"
                    ? "Chilled water supply temperature"
                    : "Cooling load"}
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
            {showLiveType === "water" ? (
              <div
                style={{
                  backgroundColor: "#F8F8F8",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <p style={{ marginBottom: "0em", fontSize: "12px" }}>
                  Set-Point Temperature
                  <strong
                    style={{
                      float: "right",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    {showNumberWithCommas(data.temp_limit)}&#176;
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      C
                    </span>
                  </strong>
                </p>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#F8F8F8",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <p style={{ marginBottom: "0em", fontSize: "12px" }}>
                  Maximum = {showNumberWithCommas(data.max_cooling)} RT
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Row className="ms-0 ps-0 main-blk">
          <Col md="8" sm="8" className="col-8 mx-0 px-0">
            <div className="table-blk">
              <div className="table-cell-blk padding-blk"></div>
              <h3
                className={`table-cell-blk blk-header${
                  theme === "light" ? "" : " dark"
                }`}
              >
                Cooling System
              </h3>
            </div>
          </Col>
          <Col md="4" sm="4" className="col-4"></Col>
        </Row>
        <Row className="mx-0 px-0 mt-4 liveStatsStarBlk">
          {/* System desktop version */}
          <Col
            lg={`${shiftOnDesktopView ? (hideChilledWater ? 6 : 4) : 2}`}
            md="4"
            sm="12"
            className="liveStatsStar add-border-right col-12 d-none d-md-inline-block"
          >
            <div className="cdn-blk-title">
              <h5>System</h5>
            </div>
            <Image className="img-fluid liveStatsStarImg" src={Power} />
            <h5
              className={`cdn-blk-text-graphik${
                theme === "light" ? "" : " dark"
              }`}
              style={{ marginTop: "0.6em" }}
            >
              {data.power}
            </h5>
          </Col>

          {/* System mobile version */}
          <Col sm="12" className="d-block d-md-none">
            <div sm="4" className="col-5 liveStatsStarMobile">
              <Image className="img-fluid liveStatsStarImg" src={Power} />
              <h5
                className={`cdn-blk-text-graphik${
                  theme === "light" ? "" : " dark"
                }`}
                style={{ marginTop: "0.3em" }}
              >
                {data.power}
              </h5>
            </div>
            <div sm="8" className="col-7 liveStatsStarMobile">
              <div className="cdn-blk-title-mobile">
                <h5 style={{ width: "90%" }}>System</h5>
              </div>
            </div>
          </Col>

          {/* Cooling load desktop version */}
          <Col
            lg={`${shiftOnDesktopView ? (hideChilledWater ? 6 : 4) : 2}`}
            md="4"
            sm="12"
            className={`liveStatsStar ${
              !hideKaerAir || !hideChilledWater
                ? "add-border-right"
                : "add-border-none"
            } col-12 d-none d-md-inline-block`}
          >
            <div className="cdn-blk-title">
              <h5>Cooling load</h5>
            </div>
            <div>
              <Image
                className="img-fluid"
                src={Meter}
                style={{ height: "66px !important" }}
              />
              <br></br>
              <Image
                className="img-fluid"
                id="indicator"
                data-cooling={data.cooling}
                src={theme === "light" ? Indicator : IndicatorDark}
                style={{
                  marginTop: "-3em",
                  height: "30px !important",
                  transform: "rotate(" + data.deg + "deg)",
                }}
              />
            </div>
            <OverlayTrigger
              key="tooltip-cooling"
              placement="bottom"
              overlay={
                <Tooltip
                  id={`tooltip-cooling`}
                  className={`custom-tooltip${
                    theme === "light" ? "" : "-dark"
                  }`}
                >
                  Maximum = {showNumberWithCommas(data.max_cooling)} RT
                </Tooltip>
              }
            >
              <h5
                className={`cdn-blk-text-cooling cdn-blk-text${
                  theme === "light" ? "" : " dark"
                }`}
              >
                {showNumberWithCommas(data.cooling)} RT
              </h5>
            </OverlayTrigger>
          </Col>

          <hr className="d-block d-md-none mobile-hr"></hr>

          <Col sm="12" className="d-block d-md-none">
            <div sm="4" className="col-5 liveStatsStarMobile">
              <div style={{ height: "85px" }}>
                <Image className="img-fluid liveStatsStarImg" src={Meter} />
                <br></br>
                <Image
                  className="img-fluid"
                  id="indicator2"
                  src={theme === "light" ? Indicator : IndicatorDark}
                  style={{
                    marginTop: "-3em",
                    height: "30px !important",
                    transform: "rotate(" + data.deg + "deg)",
                  }}
                />
              </div>
              <h5
                className={`cdn-blk-text-cooling cdn-blk-text${
                  theme === "light" ? "" : " dark"
                }`}
              >
                {data.cooling} RT
              </h5>
            </div>
            <div sm="8" className="col-7 liveStatsStarMobile">
              <div className="cdn-blk-title-mobile">
                <h5 style={{ width: "90%" }}>Cooling load</h5>
                <i
                  className="bi bi-three-dots-vertical liveStatsModalOpener"
                  data-type="cooling"
                  onClick={showMoreIconClicked}
                ></i>
              </div>
            </div>
          </Col>

          {!hideChilledWater && (
            <>
              {/* Chilled water mobile */}
              <hr className="d-block d-md-none mobile-hr"></hr>
              <Col sm="12" className="d-block d-md-none">
                <div sm="4" className="col-5 liveStatsStarMobile">
                  <Image
                    className="img-fluid liveStatsStarImg"
                    src={theme === "light" ? Temperature : TemperatureDark}
                  />
                  <h5
                    className={`cdn-blk-text-graphik${
                      theme === "light" ? "" : " dark"
                    }`}
                    style={{ marginTop: "0.3em" }}
                  >
                    {data.temp}
                    <span style={{ fontSize: "18px" }}>&#176;C</span>
                  </h5>
                </div>
                <div sm="8" className="col-7 liveStatsStarMobile">
                  <div className="cdn-blk-title-mobile">
                    <h5 style={{ width: "90%" }}>
                      Chilled water supply temperature
                    </h5>
                    <i
                      className="bi bi-three-dots-vertical liveStatsModalOpener"
                      data-type="water"
                      onClick={showMoreIconClicked}
                    ></i>
                  </div>
                </div>
              </Col>
              {/* Chilled water desktop */}
              <Col
                lg={`${shiftOnDesktopView ? 4 : 2}`}
                md="4"
                sm="12"
                className={`liveStatsStar ${!hideKaerAir && "add-border-right"} 
          ${hideKaerAir && "add-border-none"} col-12 d-none d-md-inline-block`}
              >
                <div className="cdn-blk-title">
                  <div>
                    <h5>Chilled water supply temperature</h5>
                  </div>
                  {data.show_multiple_kaer_water && (
                    <div>
                      <a
                        className={`dropdown-toggle ${
                          theme === "light" ? "" : "dark"
                        }`}
                        href="#"
                        role="button"
                        id="chilledWaterMain"
                        onClick={onChilledWaterSupplyClicked}
                        data-widget="chilled-water-main"
                      ></a>
                    </div>
                  )}
                </div>
                <Image
                  className="img-fluid liveStatsStarImg"
                  src={theme === "light" ? Temperature : TemperatureDark}
                />
                <OverlayTrigger
                  key="tooltip-temp"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id={`tooltip-temp`}
                      className={`custom-tooltip${
                        theme === "light" ? "" : "-dark"
                      }`}
                    >
                      Set-Point Temperature <br></br>
                      <strong style={{ fontSize: "15px", fontWeight: "bold" }}>
                        {showNumberWithCommas(data.temp_limit)} &#176;
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          C
                        </span>
                      </strong>
                    </Tooltip>
                  }
                >
                  <h5
                    className={`cdn-blk-text-graphik${
                      theme === "light" ? "" : " dark"
                    }`}
                    style={{ marginTop: "0.6em" }}
                  >
                    {showNumberWithCommas(data.temp)}
                    <span style={{ fontSize: "18px" }}>&#176;C</span>
                  </h5>
                </OverlayTrigger>
              </Col>
            </>
          )}

          {!hideKaerAir ? (
            <Col lg="6" md="12">
              <Row style={{ height: "25px" }}>
                <div className="cdn-blk-title-right-div">
                  <h5 className="cdn-blk-title-right chosen_room">
                    {data.chosen_room}
                  </h5>
                  <a
                    className={`dropdown-toggle ${
                      theme === "light" ? "" : "dark"
                    }`}
                    href="#"
                    role="button"
                    id="liveStatsRooms"
                    onClick={showSpaceDialogClicked}
                    data-widget="live-main"
                  ></a>
                </div>
              </Row>
              <Row
                className={`cdn-blk-parent${theme === "light" ? "" : "-dark"}`}
              >
                <Col className="cdn-blk col-6" md="6" sm="6" lg="6">
                  <Image
                    className="img-fluid right-img liveStatsStarImg"
                    src={theme === "light" ? Temperature : TemperatureDark}
                  />
                  <h5
                    className={`cdn-blk-text${
                      theme === "light" ? "" : " dark"
                    }`}
                    style={{ marginTop: "0.3em" }}
                  >
                    {data.confer_temp}
                    <span className="text-be-float">
                      <sup>&#176;C</sup>
                    </span>
                  </h5>
                </Col>
                <Col className="cdn-blk col-6" md="6" sm="6" lg="6">
                  <Image
                    className="img-fluid right-img liveStatsStarImg"
                    src={theme === "light" ? Water : WaterDark}
                  />
                  <h5
                    className={`cdn-blk-text${
                      theme === "light" ? "" : " dark"
                    }`}
                    style={{ marginTop: "0.35em" }}
                  >
                    {data.water}
                    <span className="text-be-float">
                      <sup>% RH</sup>
                    </span>
                  </h5>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col lg="6" md="12" className=""></Col>
          )}
        </Row>
      </Row>

      {!hideKaerWater && (
        <Row
          className={`rounded px-4 py-4 stats-blk-multi${
            theme === "light" ? "" : "-dark"
          }`}
        >
          <Col md="8" sm="8" className="col-8 mx-0 px-0">
            <div className="table-blk">
              <div className="table-cell-blk padding-blk"></div>
              <h3
                className={`table-cell-blk blk-header${
                  theme === "light" ? "" : " dark"
                }`}
              >
                {heading}
              </h3>
            </div>
          </Col>
          <Row
            className="mx-0 pt-4 px-0"
            style={{ marginLeft: "0px !important" }}
          >
            {water_data.length > 0 &&
              water_data.map((_, index) => {
                return (
                  <Col
                    lg="3"
                    md="6"
                    sm="12"
                    key={index}
                    data-id={_.id}
                    className="px-2 mx-0 space-stats-wrapper"
                    draggable="true"
                    onDragStart={dashboardDragStart}
                    onDragOver={dashboardDragOver}
                    onDrop={dashboardDrop}
                    onDragEnd={dashboardDragEnd}
                  >
                    <div className="space-stats mb-2">
                      <div className="space-stats-top">
                        <span className="space-title">
                          <Image
                            className="img-fluid"
                            src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                          />
                          &nbsp;&nbsp;<h6>{_.name}</h6>
                        </span>
                        <i
                          className="bi bi-gear-fill settings-icon"
                          role="button"
                          id={_.id}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        ></i>
                        <ul className="dropdown-menu" aria-labelledby={_.id}>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-choice="day"
                              data-id={_.id}
                              onClick={removeWidgetClicked}
                            >
                              Remove
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="space-stats-bottom"
                        style={{ width: "100%" }}
                      >
                        <div style={{ marginX: "auto" }}>
                          <Image
                            className="img-fluid"
                            src={
                              theme === "light" ? Temperature : TemperatureDark
                            }
                          />
                          <h5
                            className={`cdn-blk-text${
                              theme === "light" ? "" : " dark"
                            }`}
                            style={{ marginTop: "0.3em" }}
                          >
                            {_.temperature}
                            <span className="text-be-float">&#8451;</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {_.flag && (
                      <div className="rounded space-bottom mb-4">
                        <p>
                          {" "}
                          <i className="bi bi-exclamation-circle"></i>&nbsp;
                          Temperature is out of range
                        </p>
                      </div>
                    )}
                  </Col>
                );
              })}
            <Col
              lg="3"
              md="6"
              sm="12"
              style={{
                paddingLeft: "1.3em",
                paddingRight: "1.3em",
                paddingBottom: "3em",
              }}
            >
              <BlankBlk handleShow={props.handleShow} />
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
}

export default statsBlkMulti;
