// views
import BillingBlk from "../../components/billingBlk";
import BlankBlk from "../../components/blankBlk";
import ChartBlk from "../../components/chartBlk";
import ContactBlk from "../../components/contactBlk";
import DataBlk from "../../components/dataBlk";
import LiveBlk from "../../components/liveBlk";
import NotiBlk from "../../components/notificationBlk";
import ProfileBlk from "../../components/profileBlk";
import ResourceBlk from "../../components/resourceBlk";
import StatsBlk from "../../components/liveStatsBlk";
import WaterBlk from "../../components/waterBlk";

function DashboardBlk (props) {

  // console.log("Dashboard Blk props: ", props);

    return (
        <div
          style={{ padding: "5px" }}
          key={props.id}
          draggable="true"
          className="tile"
          data-id={props.id}
        >
          {props.tile_type === "Chart" ? (
            <ChartBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
            />
          ) : props.tile_type === "Data" ? (
            <DataBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              chosen_date={props.chosen_date}
              dataChoiceClicked={props.dataChoiceClicked}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Notification" ? (
            <NotiBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              readNotification={props.readNotification}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Resource" ? (
            <ResourceBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              removeWidgetClicked={props.removeWidgetClicked}
              chosen_type={props.chosen_type}
              chosen_text={props.chosen_text}
              resource_types={props.all_available_resource_types}
              resoChoiceClicked={props.resoChoiceClicked}
            />
          ) : props.tile_type === "Contact" ? (
            <ContactBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Profile" ? (
            <ProfileBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type=""
              chart_title=""
              data={props.data}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Billing" ? (
            <BillingBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Live" ? (
            <LiveBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              selected={props.selected}
              showSpaceDialogClicked={props.showSpaceDialogClicked}
              removeWidgetClicked={props.removeWidgetClicked}
            />
          ) : props.tile_type === "Chilled water supply" ? (
            <WaterBlk
              id={props.id}
              heading={props.tile_heading}
              chart_type={props.chart_type}
              chart_title={props.chart_title}
              data={props.data}
              selected={props.selected}
              showWaterSpaceDialogClicked={props.showWaterSpaceDialogClicked}
              removeWidgetClicked={props.removeWidgetClicked}
            />) : (<div></div>)}
        </div>
    )

}
export default DashboardBlk;