import React from "react";
import { Row, Col } from "react-bootstrap";
import { CollectionView } from "wijmo/wijmo";
import * as wjCore from "wijmo/wijmo";
import {
  FlexChart,
  FlexChartSeries,
  FlexChartAxis,
  FlexChartLegend,
} from "wijmo/wijmo.react.chart";
import { FlexChartAnimation } from "wijmo/wijmo.react.chart.animation";

// apply Wijmo license key
import { setLicenseKey } from "wijmo/wijmo";

// image & css
import "./chartBlk.css";

function chartBlk(props) {

  setLicenseKey(
    "GrapeCity,427351286422477#B0JoIIklkIs4nIzYXMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQvAVW7ZWbqNjeWZzRh9Ucl5UTuZVaCR7ZpB5UH9EVC3kaqJWZ0pnasJ7Q9I4bB3GR0F6aIt6NZ96MFN4aotEZrUXe4kHUlllerlGb9dDSPhEcFFmclJXd8syNEtENzMTOBNVYpFje6lDUlBlbkdmcNNGOrAHR9pXNSl6NpVkbCNWUxlkZ7o7QT3icHNGavFXdapWQDZ5KsN5N7dDcyRUW85ESFBlb4JUZq3GWlBldXBzU0hjW9wkb8cncopnSOBjNkJFdyNmSqB7YVlnNzpnb9BDSMllTSFDaNFjca54dtBXatlEdS5mVNV6dxIEN7RUNxYGSvU7KIdndPpENvlXW6hzbCh7RsZ7YLJzboNnU7ZERTRkVBNlN7RWSGhDVrdmZqZ4cq94aFpkbWZDMGBHdVZ5YwUTTIdlN0RnVvMDdFJzbQt6bolDM5NkdrsUO536LrNWSotmI0IyUiwiIDRTR4MjM9QjI0ICSiwSMyIjM9UTMwEjM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiETMwAzNwACOwcDMwIDMyIiOiQncDJCLi46bj9idlRWe4l6YlBXYydmLqwibj9SbvNmL9RXajVGchJ7ZuoCLt36YukHdpNWZwFmcn9iKsI7au26YukHdpNWZwFmcn9iKsAnau26YukHdpNWZwFmcn9iKiojIz5GRiwiI9RXaDVGchJ7RiojIh94QiwiI7cDNyIDN6gjMxUzMdI6N"
  );

  const theme = localStorage.getItem("theme");
  const chart_type = props.chart_type;
  const chart_title = props.chart_title;
  const heading = props.heading;
  const data = props.data;

  const palette = [
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ];
  const tooltip = `<b>Date: </b>{date:dd MMMM, yyyy} </br>temp: {mean}`;

  if (theme == "dark") {
    var styles = `
      .wj-tooltip {
        color: #FFFFFF !important;
        font-family: 'Graphik' !important;
      }
      .custom-chart text {
        fill: #FFFFFF !important;
        font-family: 'Graphik' !important;
      }
    `;
  } else {
    var styles = `
      .wj-tooltip {
        font-family: 'Graphik' !important;
      }
      .custom-chart text {
        font-family: 'Graphik' !important;
      }
    `;
  }
  var styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  return (
    <Row className={`rounded charts-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 chart-main-blk">
      <Col md="8" sm="8" className="col-8 chart-sub-blk">
        <div className="chart-blk">
          <div className="chart-cell-blk chart-padding-blk padding-blk"></div>
          <h3 className={`chart-cell-blk chart-blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
        </div>
      </Col>
      <Col md="4" sm="4" className="col-4 chart-sub-blk">
        <i className="bi bi-gear-fill chart-settings-icon"></i>
      </Col>
      </Row>
      <Row className="ms-0 chart-chart">
        <div>
          <p className="chart-blk-title">{chart_title}</p>
          <a
            className={`chartDrop dropdown-toggle ${
              theme === "light" ? "" : "dark"
            }`}
            href="#"
            role="button"
            id="liveStatsDrop123"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></a>
          <ul className="dropdown-menu" aria-labelledby="liveStatsDrop123">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </div>
        {chart_type === "area_chart" ? (
          <FlexChart
            className="custom-chart"
            itemsSource={data}
            bindingX="date"
            chartType="Area"
            palette={palette}
          >
            <FlexChartLegend position="None"></FlexChartLegend>
            <FlexChartAxis
              wjProperty="axisX" labelAngle={-45}
            ></FlexChartAxis>
            <FlexChartAxis
              wjProperty="axisY"
              title="Temperature(°C)"
              StrokeColor="#FFFFFF"
            ></FlexChartAxis>
            <FlexChartSeries
              binding="mean"
              name="Mean Temperature"
              tooltipContent={tooltip}
            ></FlexChartSeries>

            <FlexChartAnimation animationMode="Point"></FlexChartAnimation>
          </FlexChart>
        ) : (
          <div></div>
        )}
      </Row>
    </Row>
  );
}

export default chartBlk;
