import React, { useState, useEffect } from "react";
import { options, data, plugins } from "./chart_two_setting";
import { Line } from "react-chartjs-2";
import "./Chart.css";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SelectWaterModal from "../../../components/selectWaterModal";

const ChartTwo = React.memo((props) => {
  const { title, data_count } = props;
  const [count, setCount] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [label, setLabel] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [rooms, setRooms] = useState([]);

  const LOCAL_STORAGE_KEY = "selected_block_chilled_water";
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedRoom, setSelectedRoom] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY)
  );

  const maxWidth765 = useMediaQuery({ maxWidth: 765 });
  const maxWidth500 = useMediaQuery({ maxWidth: 500 });

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  useEffect(() => {
    if (data_count && data_count.graph) {
      if (data_count.show_multiple_kaer_water) {
        if (data_count.graph.kw_data?.room_list?.length > 0) {
          const roomsData = data_count.graph.kw_data.room_list;

          setRooms(
            roomsData.map((room) => {
              return { name: room };
            })
          );

          // check if selected room is in the list
          let currentRoom = selectedRoom;
          let data = data_count.graph.kw_data.space_data[currentRoom];
          if (data == null) {
            currentRoom = roomsData[0];
            data = data_count.graph.kw_data.space_data[currentRoom];
            setSelectedRoom(roomsData[0]);
          }

          if (data) {
            let datas = [];
            let labels = [];

            Object.entries(data)
              .sort(([key, value], [key2, value2]) => key - key2)
              .forEach(([key, value]) => {
                labels.push(key);
                datas.push(value);
              });

            setLabel(labels.sort((a, b) => a - b));
            setCount(datas);
          }

          return;
        }
      }

      if (data_count.graph.chilled_water) {
        let datas = [];
        let labels = [];

        Object.entries(data_count.graph.chilled_water)
          .sort(([key, value], [key2, value2]) => key - key2)
          .forEach(([key, value]) => {
            labels.push(key);
            datas.push(value);
          });

        setLabel(labels.sort((a, b) => a - b));
        setCount(datas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_count]);

  useEffect(() => {
    if (!data_count.graph) return;
    if (!data_count.graph.kw_data) return;
    const data = data_count.graph.kw_data.space_data[selectedRoom];

    if (data) {
      let datas = [];
      let labels = [];

      Object.entries(data)
        .sort(([key, value], [key2, value2]) => key - key2)
        .forEach(([key, value]) => {
          labels.push(key);
          datas.push(value);
        });

      setLabel(labels.sort((a, b) => a - b));
      setCount(datas);
    }

    if (!data) {
      setCount([]);
      setLabel([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom]);

  const onSelectBlock = (e) => {
    if (e.target.getAttribute("disabled")) {
      return;
    }

    setSelectedRoom(selectedBlock);
    localStorage.setItem(LOCAL_STORAGE_KEY, selectedBlock);
    setModalShow(false);
  };

  return (
    <div className="charts_container_mobile">
      <SelectWaterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        spaceBlocks={rooms}
        allChosenSpaces={[selectedRoom]}
        selectedBlock={selectedBlock}
        onBlockClicked={onSelectBlock}
        onBlockMouseOver={(e) => {
          setSelectedBlock(e.target.getAttribute("data-id"));
        }}
      />
      <div className={`data_big_chart_container ${theme}`}>
        <div
          className="space_container"
          style={
            !maxWidth765
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }
              : {}
          }
        >
          <div className="title">{title}</div>
          {data_count.show_multiple_kaer_water && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 13,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 8,
                }}
              >
                {selectedRoom}
              </div>
              <div
                style={{
                  fontSize: 13,
                  background: "#005DAA",
                  color: "#fff",
                  width: 100,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 4,
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                onClick={() => setModalShow(true)}
              >
                Change
              </div>
            </div>
          )}
        </div>
        <div style={{ height: 350 }}>
          <Line
            options={options(maxWidth765, maxWidth500, label)}
            data={data(count, label)}
            plugins={plugins}
          />
        </div>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
});
export default connect(mapStateToProps, null)(ChartTwo);
