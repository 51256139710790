import { Row, Col, Modal, Image } from "react-bootstrap";
// icons
import SpaceIcon from "../../assets/images/live/space.svg";
import SpaceDarkIcon from "../../assets/images/live/space-dark.svg";
import LayersIcon from "../../assets/images/live/layers.svg";
import LayersDarkIcon from "../../assets/images/live/layers-dark.svg";
import CubeIcon from "../../assets/images/live/cube.svg";
import CubeDarkIcon from "../../assets/images/live/cube-dark.svg";

function DashboardSpaceModal(props) {

  let theme = localStorage.getItem("theme");

  // console.log('props.spaceBlocks:', props.spaceBlocks);

  const SpaceDialogContent = () => {
    return (
      <>
        {props.spaceCats.map((value, index) => {
          return (
            <div
              key={index}
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name={`btnMain${index}`}
                id={`btnMain${index}`}
                autoComplete="off"
                onClick={props.spaceCatClicked}
              />
              <label
                className={`btn add-space-type-dashboard ${
                  props.selectedSpaceCat === value && "active"
                } ${theme === "light" ? "" : "dark"}`}
                htmlFor={`btnMain${index}`}
              >
                {value}
              </label>
            </div>
          );
        })}
        <hr className="d-none d-lg-block"></hr>
        <br className="d-block d-lg-none"></br>
        <br className="d-block d-lg-none"></br>
        <Row>
          <Col lg="2" md="2">
            <div
              className={`d-none d-lg-block space-type-chooser ${
                theme === "light" ? "" : "dark-blk"
              }`}
            >
              <Image
                className="img-fluid"
                src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                style={{ paddingBottom: "3px" }}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  paddingLeft: "10px",
                }}
              >
                <b>Type</b>
              </p>
              {props.spaceTypes.map((value, index) => {
                return (
                  <div key={index}>
                    {index !== 0 && <hr></hr>}
                    <p
                      className={`space-type-info ${
                        props.selectedSpaceType === value && "active"
                      }`}
                      onClick={props.spaceTypeClicked}
                    >
                      {value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              className="d-block d-lg-none accordion accordion-flush space-type-chooser-accordion"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <Image
                      className="img-fluid"
                      src={theme === "light" ? SpaceIcon : SpaceDarkIcon}
                    />
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <b>Type</b>
                    </p>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {props.spaceTypes.map((value, index) => {
                      return (
                        <div key={index}>
                          {index !== 0 && <hr></hr>}
                          <p
                            className={`space-type-info ${
                              props.selectedSpaceType === value && "active"
                            }`}
                            onClick={props.spaceTypeClicked}
                          >
                            {value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="10" md="10">
            <div style={{ paddingTop: "10px" }}>
              <Image
                className="img-fluid"
                style={{ verticalAlign: "text-bottom" }}
                src={theme === "light" ? LayersIcon : LayersDarkIcon}
              />
              <p
                style={{
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "400",
                  paddingLeft: "10px",
                  verticalAlign: "-webkit-baseline-middle",
                }}
              >
                <b>Floor</b>
              </p>
              <div className="middle-padding-space-blk d-none d-lg-inline-flex">
                <hr></hr>
              </div>
              <br className="d-block d-lg-none"></br>
              <p className="paging-space-blk d-none d-lg-inline-block">
                {props.spaceFloors.map((value, index) => {
                  return (
                    <span key={index}>
                      <span
                        className={`paging-space-page ${
                          value === props.selectedSpaceFloor && "active"
                        }`}
                        onClick={props.spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={props.spaceFloorPagingClicked}
                >
                  &gt;
                </span>
              </p>
              <p className="paging-space-blk-mobile d-block d-lg-none">
                {props.spaceFloors.map((value, index) => {
                  return (
                    <span key={index}>
                      <span
                        className={`paging-space-page-mobile ${
                          value === props.selectedSpaceFloor && "active"
                        }`}
                        onClick={props.spaceFloorClicked}
                      >
                        {value}
                      </span>
                    </span>
                  );
                })}
                <span
                  className="paging-space-page"
                  onClick={props.spaceFloorPagingClicked}
                >
                  &gt;
                </span>
              </p>
            </div>
            <div>
              <Row>
                {props.spaceBlocks !== undefined ? (
                  props.spaceBlocks.map((_, index) => {
                    return (
                      <Col md="3" sm="6" className="spacer col-6" key={index}>
                        <div
                          className={`rounded space-floor-blk ${
                            props.selectedBlock === _.name &&
                            props.allChosenSpaces.indexOf(props.selectedBlock) < 0 &&
                            "active"
                          } ${
                            props.allChosenSpaces.indexOf(_.name) > -1 && "disabled"
                          }`}
                          disabled={
                            props.allChosenSpaces.indexOf(_.name) > -1
                              ? "disabled"
                              : ""
                          }
                          onClick={props.spaceBlkClicked}
                          onMouseOver={props.addSpaceBlkMouseHover}
                          onMouseLeave={props.addSpaceBlkMouseLeave}
                          data-id={_.name}
                        >
                          <Image
                            className="img-fluid"
                            onMouseOver={props.addSpaceBlkMouseHover}
                            data-id={_.name}
                            src={
                              theme === "light" &&
                              props.selectedBlock === _.name &&
                              props.allChosenSpaces.indexOf(props.selectedBlock) < 0
                                ? CubeDarkIcon
                                : theme === "light" && props.selectedBlock !== _.name
                                ? CubeIcon
                                : CubeIcon
                            }
                          />
                          <p
                            className="space-blk-level"
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              lineHeight: "0.5em",
                            }}
                          >
                            <b>{_.level}</b>
                          </p>
                          <p
                            className="space-blk-name"
                            data-id={_.name}
                            style={{ lineHeight: "1em", textAlign: "left" }}
                          >
                            {_.name}
                          </p>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  };

    return (
        <Modal show={props.showSpaceDialog} onHide={props.handleCloseSpaceDialog}>
            <Modal.Header
              closeButton
              className={`${theme === "light" ? "" : "dark dark-blk"}`}
            >
              <Modal.Title>
                <div className="modal-padding-blk"></div>{" "}
                <div className="modal-title-blk">
                  <span className={`${theme === "light" ? "" : "dark"}`}>
                    Select
                  </span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
              <SpaceDialogContent />
            </Modal.Body>
        </Modal>
    )
}
export default DashboardSpaceModal;