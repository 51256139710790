import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Redirect } from "react-router-dom";
import { push } from "react-router-redux";
import {
  userLogout
} from "../store/actions/loginUserAction";
import { changePage } from "../store/actions/urlAction";
// routes
import Dashboard from "../views/dashboard";
import ForceResetPassword from "../views/forceResetPassword";
import ComingSoon from "./comingSoon";

// image & css
import Logo from "../assets/images/logo/login_logo.png";
import DarkLogo from "../assets/images/logo/login_logo_dark.png";
import Avatar from "../assets/images/navigation/avatar.svg";
import BillingAltIcon from "../assets/images/navigation/billing-alt.svg";
import BillingIcon from "../assets/images/navigation/billing.svg";
import ContactAltIcon from "../assets/images/navigation/contact-alt.svg";
import ContactIcon from "../assets/images/navigation/contact.svg";
import DashboardAltIcon from "../assets/images/navigation/dashboard-alt.svg";
import DashboardIcon from "../assets/images/navigation/dashboard.svg";
import DataAltIcon from "../assets/images/navigation/data-alt.svg";
import DataIcon from "../assets/images/navigation/data.svg";
import LiveAltIcon from "../assets/images/navigation/live-alt.svg";
import LiveIcon from "../assets/images/navigation/live.svg";
import NotificationsAltIcon from "../assets/images/navigation/notifications-alt.svg";
import NotificationsIcon from "../assets/images/navigation/notifications.svg";
import PortfolioAltIcon from "../assets/images/navigation/portfolio-alt.svg";
import PortfolioIcon from "../assets/images/navigation/portfolio.svg";
import ProfileAltIcon from "../assets/images/navigation/profile-alt.svg";
import ProfileIcon from "../assets/images/navigation/profile.svg";
import ResourcesAltIcon from "../assets/images/navigation/resources-alt.svg";
import ResourcesIcon from "../assets/images/navigation/resources.svg";
import "./navigation.css";

import {
  DASHBOARD_ROUTE
} from "../routes";

// API
import api from "../config/api";
import config from "../config/config";

// Feedback
import StarRatings from "react-star-ratings";

function Navigation(props) {
  const { LOGOUT_USER, SET_RATING } = config.api_endpoint;
  const [loading, setLoading] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const { AuthDetail, CSRFToken } = props;
  const [refreshVal, setRefreshVal] = useState(AuthDetail.refresh_interval);
  const isMobile = useMediaQuery({ maxWidth: 765 });

  const theme = localStorage.getItem("theme");
  if (theme === "light") {
    document.body.style.backgroundColor = "#FFFFFF";
  } else {
    document.body.style.backgroundColor = "#0B0B0B";
  }
  const selected = props.selected;
  const children = props.children;
  const img_token = props.img_token;

  const getCurrentSite = config.getCurrentSite;

  const menuClicked = (event) => {
    var clicked =
      event.target.getAttribute("data-menu") ||
      event.target.parentNode.getAttribute("data-menu");

    if (clicked) {
      push(clicked + "/" + getCurrentSite());
      props.changePage(clicked + "/" + getCurrentSite());
    }
  };

  const themeChanged = (event) => {
    event.preventDefault();

    var theme = localStorage.getItem("theme");
    if (theme == "dark") {
      localStorage.setItem("theme", "light");
    } else {
      localStorage.setItem("theme", "dark");
    }
    window.location.reload();
  };

  const [showSiteChanged, setShowSiteChanged] = useState(false);
  const [currentSelectedSite, setCurrentSelectedSite] = useState(getCurrentSite());
  const rdoSiteClicked = (item) => {
    setCurrentSelectedSite(item.name);
    // var radios = document.getElementsByClassName("rdoSite");
    //
    // for (var i = 0; i < radios.length; i++) {
    //   if (radios[i].name != event.target.getAttribute("data-name")) {
    //     radios[i].checked = false;
    //   }
    //   else {
    //     radios[i].checked = true;
    //   }
    // }

  };

  const rdoSiteConfirmClicked = () => {
    var old_value = getCurrentSite();
    document.getElementById("kaer_current_now").value = currentSelectedSite;

    push(window.location.pathname.replace(old_value, currentSelectedSite).substring(1));
    props.changePage(window.location.pathname.replace(old_value, currentSelectedSite).substring(1));

    setShowSiteChanged(false);
    window.location.reload();
    // var radios = document.getElementsByClassName("rdoSite");
    //
    // for (var i = 0; i < radios.length; i++) {
    //   if (radios[i].checked) {
    //     var old_value = getCurrentSite();
    //     document.getElementById("kaer_current_now").value = radios[i].name;
    //
    //     push(window.location.pathname.replace(old_value, radios[i].name).substring(1));
    //     props.changePage(window.location.pathname.replace(old_value, radios[i].name).substring(1));
    //
    //     setShowSiteChanged(false);
    //     window.location.reload();
    //     break;
    //   }
    // }
  };

  const siteChanged = (event) => {
    event.preventDefault();

    setShowSiteChanged(true);

    // console.log("siteChanged >>> ", localStorage.getItem("selected_site"));
    // console.log("user sites >>> ", AuthDetail.sites);
    //
    // if (localStorage.getItem("selected_site") && AuthDetail.sites.length > 1) {
    //   for (var i = 0; i < AuthDetail.sites.length; i++) {
    //     if (AuthDetail.sites[i]["name"] != localStorage.getItem("selected_site")) {
    //       localStorage.setItem("selected_site", AuthDetail.sites[i]["name"]);
    //       break;
    //     }
    //   }
    //
    //   window.location.reload();
    // }
    // else {
    //   alert("No other available site assigned.")
    // }
  };

  const logoutFunction = () => {
    setLoading(true);
    api
      .post(LOGOUT_USER, JSON.stringify({}), CSRFToken)
      .then((result) => {
        console.log("result: ", result);
        props.setSignIn(false);
        props.userLogout();
        localStorage.setItem("theme", "light");
        localStorage.removeItem("selected_blocks_datapage");
        localStorage.removeItem("selected_site");
        localStorage.removeItem("selectedDate");
        localStorage.removeItem("selectedDateMethod");
        window.location.href = "/";
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        props.setSignIn(false);
        window.location.href = "/";
      });
  };

  const logoutClicked = (event) => {
    event.preventDefault();
    // 10%
    var prob = Math.floor(Math.random() * 10) + 1;
    console.log("probability: ", prob);
    if (prob == 1) {
        setShowFeedback(true);
    }
    else {
        logoutFunction();
    }
  };

  const refreshIntClicked = (event) => {
    event.preventDefault();
    setShowRefreshModal(true);
  };

  const refreshIntModalSave = (event) => {
    event.preventDefault();
    localStorage.setItem(
      "refresh_interval",
      document.getElementById("refresh_value").value
    );
    setRefreshVal(document.getElementById("refresh_value").value);
    setShowRefreshModal(false);
    window.location.reload();
  };

  const refreshIntModalClose = (event) => {
    event.preventDefault();
    setShowRefreshModal(false);
  };

  const seeProfileClicked = (event) => {
    event.preventDefault();
    push("profile");
    props.changePage("profile");
  };

  const [menus, setMenus] = useState([
    {
      title: "Dashboard",
      name: "dashboard",
      icon: DashboardIcon,
      icon_alt: DashboardAltIcon,
    },
    {
      title: "Live",
      name: "live",
      icon: LiveIcon,
      icon_alt: LiveAltIcon,
    },
    {
      title: "Data",
      name: "data",
      icon: DataIcon,
      icon_alt: DataAltIcon,
    },
    {
      title: "Portfolio",
      name: "portfolio",
      icon: PortfolioIcon,
      icon_alt: PortfolioAltIcon,
    },
    {
      title: "Billing",
      name: "billing",
      icon: BillingIcon,
      icon_alt: BillingAltIcon,
    },
    {
      title: "Resources",
      name: "resources",
      icon: ResourcesIcon,
      icon_alt: ResourcesAltIcon,
    },
    // {
    //   title: "Insights",
    //   name: "insights",
    //   icon: InsightsIcon,
    //   icon_alt: InsightsAltIcon,
    // },
    {
      title: "Notifications",
      name: "notifications",
      icon: NotificationsIcon,
      icon_alt: NotificationsAltIcon,
    },
    {
      title: "Contact",
      name: "contact",
      icon: ContactIcon,
      icon_alt: ContactAltIcon,
    },
    {
      title: "Profile",
      name: "profile",
      icon: ProfileIcon,
      icon_alt: ProfileAltIcon,
    },
    {
      title: "User Management",
      name: "user_management",
      icon: ProfileIcon,
      icon_alt: ProfileAltIcon,
    }
  ]);

  const NavigationMenus = () => {
    return (
      <Nav
        defaultActiveKey="/home"
        className="flex-column px-1 navigation-bar"
        data-theme={theme}
      >
        <Nav.Item className="pt-2 px-2 pb-4">
          <Image
            className="d-none d-md-block mt-3 navigation-company-logo img-fluid"
            src={theme === "light" ? Logo : DarkLogo}
          />
        </Nav.Item>
        {menus.length > 0 ? (
          menus?.map((_, index) => {
            return (
              <Nav.Item
                className={`p-1 ${index === 7 ? "pb-4" : "pb-2"}`}
                key={index}
              >
                <div
                  className={`navigation-circle${
                    selected === _.name
                      ? ""
                      : selected.includes("billing")
                      ? _.name.includes("billing")
                        ? ""
                        : "-no-color"
                      : "-no-color"
                  }${theme === "light" ? "" : "-dark"}`}
                >
                  <Image
                    onClick={menuClicked}
                    data-menu={_.name}
                    className={`navigation-icon
                    ${
                      selected.includes("billing")
                        ? _.name.includes("billing")
                          ? theme == "light"
                            ? "_.icon_alt"
                            : "_.icon"
                          : "_.icon"
                        : "weee"
                    }`}
                    src={
                      selected.includes("billing")
                        ? _.name.includes("billing")
                          ? theme == "light"
                            ? _.icon_alt
                            : _.icon
                          : theme === "light" && selected !== _.name
                          ? _.icon
                          : theme === "light" && selected === _.name
                          ? _.icon_alt
                          : theme === "dark" && selected !== _.name
                          ? _.icon_alt
                          : _.icon
                        : theme === "light" && selected !== _.name
                        ? _.icon
                        : theme === "light" && selected === _.name
                        ? _.icon_alt
                        : theme === "dark" && selected !== _.name
                        ? _.icon_alt
                        : _.icon
                    }
                  />
                </div>
                <Nav.Link
                  eventKey="link-1"
                  onClick={menuClicked}
                  data-menu={_.name}
                  className={`d-none d-md-block p-0 ${
                    selected === _.name && "selected"
                  } navigation-link${theme === "light" ? "" : "-dark"}`}
                >
                  {_.title}
                </Nav.Link>
              </Nav.Item>
            );
          })
        ) : (
          <div></div>
        )}
      </Nav>
    );
  };

  const MobileNavigationMenus = () => {
    return (
      <Navbar collapseOnSelect expand="lg" bg="#FFFFFF">
        <Navbar.Brand href="#home">
          <Image
            className="navigation-company-logo-mobile img-fluid"
            src={theme === "light" ? Logo : DarkLogo}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {menus.length > 0 ? (
            menus?.map((_, index) => {
              return (
                <Nav.Item
                  className={`p-1 ${index === 7 ? "pb-2" : "pb-1"}`}
                  key={index}
                >
                  <Nav.Link
                    eventKey="link-1"
                    onClick={menuClicked}
                    data-menu={_.name}
                    className="navigation-link"
                    data-toggle="collapse"
                    data-target="nav-collapse"
                  >
                    {_.title}
                  </Nav.Link>
                </Nav.Item>
              );
            })
          ) : (
            <div></div>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  };

  const [showFeedback, setShowFeedback] = useState(false);
  const [rating, setRating] = useState(0);
  const [disableFeedback, setDisableFeedback] = useState(true);

  const changeRating = (newRating, name) => {
    setRating(newRating);
    setDisableFeedback(false);
  };

  const skipRating = () => {
    setShowFeedback(false);
    logoutFunction();
  };

  const saveRating = () => {
    api
      .post(SET_RATING, JSON.stringify({ rating: rating }), CSRFToken)
      .then((result) => {
        console.log("save rating result: ", result);
      })
      .catch((err) => {
        console.log("save rating err", err);
      })
      .finally(() => {
        setShowFeedback(false);
        logoutFunction();
      });
  };

  useEffect(() => {
    if (AuthDetail.need_reset_password && window.location.href.indexOf("set_password") < 0) {
      window.location.href = "/set_password";
    }
    // click anywhere to close the menu bar in mobile...
    document.addEventListener("click", function (event) {
      var clickedTarget = event.target;
      var nodes = document.getElementsByClassName("navbar-collapse");
      var classNames = [
        "navigation-link",
        "nav-link",
        "navbar-toggler",
        "navbar-toggler-icon",
      ];

      var flag = true;

      for (var i = 0; i < classNames.length; i++) {
        if (clickedTarget.classList.contains(classNames[i])) {
          flag = false;
        }
      }

      if (flag) {
        if (nodes.length == 1) {
          if (nodes[0].classList.contains("show")) {
            document.getElementsByClassName("navbar-toggler")[0].click();
          }
        }
      }
    });

    var toMenus = [];
    menus.forEach((menu, i) => {
      if (menu["name"] != "dashboard" && menu["name"] != "profile") {
        if (
          AuthDetail.permissions.indexOf("access_" + menu["name"] + "_module") >
          -1
        ) {
          toMenus.push(menu);
        }
      } else {
        toMenus.push(menu);
      }
    });
    setMenus(toMenus);
  }, []);

  useEffect(() => {
    const panels = [
      document.querySelector(".navigation-right-hide"),
      document.querySelector(".navigation-right"),
    ];

    panels.forEach(function (panel) {
      if (panel) {
        if (selected === "notifications") {
          panel.classList.remove("blue-background");
          panel.classList.remove("light-blue-background");
          panel.classList.add("red-background");
        } else if (selected === "") {
          panel.classList.remove("blue-background");
          panel.classList.remove("red-background");
          panel.classList.add("light-blue-background");
        }
        else if (selected === "data") {
          panel.classList.remove("blue-background");
          panel.classList.remove("red-background");
          panel.classList.add("light-orange-background");
        }
        else {
          panel.classList.remove("red-background");
          panel.classList.remove("light-blue-background");
          panel.classList.add("blue-background");
        }
      }
    });

    if (selected != "set_password" && selected != "dashboard" && selected != "profile" && selected != "") {
      var found = false;

      if (
        AuthDetail.permissions.indexOf("access_" + selected + "_module") > -1
      ) {
        found = true;
      }

      if (!found) {
        setLoading(true);
        alert("No permission to access the module.");
        window.location.href = "/";
      }
    }
  }, [selected]);

  return (
    <Container
      fluid
      data-theme={theme}
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        overflowX: "hidden",
      }}
    >

      <Modal show={showSiteChanged}
        className="small-modal"
      >
        <Modal.Header
          closeButton
          className={`${theme === "light" ? "" : "dark dark-blk"}`}
          onHide={function () {
            setShowSiteChanged(false);
          }}
        >
          <Modal.Title>
            Change Site
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: "0px" }}></hr>
        <Modal.Body
          className={`${
            theme === "light" ? "" : "dark dark-blk"
          }`}
        >
          <div style={{ paddingLeft: "20%" }}>
            {AuthDetail.company?.map((item, i) => {
                return (
                  <div key={i}>
                    <input
                      className="rdoSite"
                      type="radio"
                      name={`${item.name}`}
                      data-name={`${item.name}`}
                      autoComplete="off"
                      checked={item.name == currentSelectedSite ? true : false}
                      onChange={() => rdoSiteClicked(item)}
                    /><label
                      className="rdoSiteLabel"
                      data-name={`${item.name}`}
                      onClick={() => rdoSiteClicked(item)}>&nbsp;{item.title}</label>
                  </div>
                )
            })}
            <br></br>
            <Button
              variant="primary"
              className="btnConfirmSite"
              onClick={rdoSiteConfirmClicked}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showFeedback} className="small-modal">
        <Modal.Body
          className={`${
            theme === "light" ? "text-center" : "text-center dark dark-blk"
          }`}
        >
          <StarRatings
            rating={rating}
            starRatedColor="blue"
            changeRating={changeRating}
            numberOfStars={5}
            name="rating"
          />
          <br></br>
          <br></br>
          <h5>How was your experience today?</h5>
          <p>Rate from 1-5 stars</p>
          <Button
            variant="secondary"
            className="feedbackBtn"
            onClick={skipRating}
          >
            Skip
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="primary"
            className="feedbackBtn"
            onClick={saveRating}
            disabled={disableFeedback ? "disabled" : ""}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={showRefreshModal} className="small-modal">
        <Modal.Body
          className={`${
            theme === "light" ? "text-center" : "text-center dark dark-blk"
          }`}
        >
          <label>Refresh Interval (mins):</label>&nbsp;&nbsp;
          <input type="number" defaultValue={refreshVal} id="refresh_value" />
        </Modal.Body>
        <Modal.Footer className={`${theme === "light" ? "" : "dark dark-blk"}`}>
          <Button variant="secondary" onClick={refreshIntModalClose}>
            Close
          </Button>
          <Button className="refresh-save" onClick={refreshIntModalSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {loading ? (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      ) : !loading && AuthDetail.need_reset_password ?(
        <ForceResetPassword setSignIn={props.setSignIn} />
      )
      :(
        <>
          <Row
            className="px-0 mx-0"
            style={{ display: "inline-block", height: "100vh" }}
          >
            <div
              className={`d-none d-md-inline-block navigation-bar-column${
                theme === "light" ? "" : "-dark"
              }`}
            >
              <NavigationMenus />
            </div>
            <div
              className={`ms-0 px-0 d-block d-md-none navigation-mobile-column${
                theme === "light" ? "" : "-dark"
              }`}
            >
              <MobileNavigationMenus />
            </div>
          </Row>
          <Row className="px-0 mx-0 navi-row">
            <div className="px-0 content-left" data-theme={theme}>
              <Row
                className={`navigation-right ${
                  selected == "data"
                    ? "bg_change_data_view"
                    : selected == "portfolio"
                    ? "bg_change_portfolio_view"
                    : selected == "billing"
                    ? "bg_change_billing_view"
                    : selected == "resources"
                    ? "bg_change_resources_view"
                    : null
                } pt-4 ps-3 pe-0 mx-0`}
                data-theme={theme}
              >
                <Col lg="6" md="6" xs="6" sm="6">
                  <h2
                    className={`text-start ${isMobile && "page-name-mobile"} ${
                      !isMobile && "page-name"
                    } `}
                  >
                    {selected === "profile"
                      ? "Hi " + props.AuthDetail.full_name + ","
                      : selected.replaceAll("_", " ")}
                  </h2>
                </Col>
                <Col lg="6" md="6" xs="6" sm="6">
                  <div
                    className="float-end d-flex align-items-center"
                    data-theme={theme}
                    style={{ marginRight: "20px" }}
                  >
                    <Image
                      className="img-fluid avatar-icon"
                      src={AuthDetail.img_thumbnail ? (`${AuthDetail.img_thumbnail}${img_token}`) : (Avatar)}
                      onClick={seeProfileClicked}
                    />
                    <p className="d-inline-block acccount-name">
                      {AuthDetail.full_name || AuthDetail.username}<br></br><br></br>
                      {AuthDetail.company?.map(function (i) {
                          if (i["name"] == getCurrentSite()) {
                            return <span style={{ lineHeight: "0.5em" }}><i style={{ fontSize: "12px" }}>{i["title"]}</i></span>;
                          }
                        }
                      )}
                    </p>
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={seeProfileClicked}
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={themeChanged}
                        >
                          Change Theme
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={siteChanged}
                        >
                          Change Site
                        </a>
                      </li>
                      <li>
                        <a
                          id="btnLogout"
                          className="dropdown-item"
                          href="#"
                          onClick={logoutClicked}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row
                className={`navigation-right-hide${
                  theme === "light" ? "" : "-dark"
                } ms-0 ${
                  selected == "data"
                    ? "bg_change_data_view"
                    : selected == "portfolio"
                    ? "bg_change_portfolio_view"
                    : selected == "billing"
                    ? "bg_change_billing_view"
                    : selected == "resources"
                    ? "bg_change_resources_view"
                    : null
                }`}
              ></Row>
              {selected === "" ? (
                <Redirect to={`${DASHBOARD_ROUTE}/${AuthDetail.company[0].name}`} component={Dashboard} />
              ) : selected === "insights" ? (
                <Row className="fixed-children px-3 mx-0">
                  <ComingSoon />
                </Row>
              ) : selected == "data" ||
                selected == "billing" ||
                selected == "portfolio" ||
                selected == "billing_pdf" ||
                selected == "resources" ? (
                <Row
                  className={`fixed-children ${!isMobile && "px-3 mx-0"} ${
                    isMobile && "go-mobile"
                  } `}
                >
                  {children}
                </Row>
              ) : (
                <Row
                  className={`fixed-children ${!isMobile && "px-3 mx-0"} ${
                    isMobile && "ps-2 pe-1 me-1 go-mobile"
                  }`}
                >
                  {children}
                </Row>
              )}
              <p className="copyrights">© 2002-2024 Kaer. All rights reserved.</p>
            </div>
          </Row>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  AuthDetail: state.loginUserDetail.userDetail,
  CSRFToken: state.loginUserDetail.csrf,
});

const mapDispatchToProps = (dispatch) => ({
  changePage: (url) => dispatch(changePage(url)),
  userLogout: () => dispatch(userLogout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
