import React from "react";
import { Row, Col, Button, Image } from 'react-bootstrap';

// image & css
import "./liveBlk.css";
import Temperature from "../assets/images/live/temperature.png";
import TemperatureDark from "../assets/images/live/temperature-dark.png";
import Water from "../assets/images/live/water.png";
import WaterDark from "../assets/images/live/water-dark.png";

function waterBlk(props) {

  const theme = localStorage.getItem("theme");
  const heading = props.heading;
  const data = props.data;
  const sub_heading2 = props.sub_heading2;
  const removeWidgetClicked = props.removeWidgetClicked;
  const showWaterSpaceDialogClicked = props.showWaterSpaceDialogClicked;
  const id = props.id;

  // console.log('WaterBlk data:', props);
  // const selected = props.selected;

  return (
    <Row className={`rounded live-blk${theme === "light" ? "" : "-dark"}`}>
      <Row className="ms-0 ps-0 main-blk">
        <Col md="10" sm="10" className="col-8">
          <div className="table-blk">
            <div className="table-cell-blk padding-blk"></div>
            <h3 className={`table-cell-blk blk-header${theme === "light" ? "" : " dark"}`}>{heading}</h3>
          </div>
        </Col>
        <Col md="2" sm="2" className="col-4">
          <i className="bi bi-gear-fill settings-icon" role="button" id={id} data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul className="dropdown-menu" aria-labelledby={id}>
            <li><a className="dropdown-item" href="#" data-choice="day" data-id={id} onClick={removeWidgetClicked}>Remove</a></li>
          </ul>
        </Col>
      </Row>
      <Row style={{ height: '25px' }}>
        <div className="cdn-blk-title-right-div">
          <h5 className="cdn-blk-title-right" id={`room-${id}`}>{data.selected_kw && data.selected_kw.name}</h5>
          <a className={`liveStatsDrop dropdown-toggle ${theme === "light" ? "" : "dark"}`} href="#" role="button"
           id="liveStatsDrop"
           onClick={showWaterSpaceDialogClicked}
           data-id={id}
           data-widget="chilled-water-small"></a>
        </div>
      </Row>
      <Row className={`cdn-blk-parent${theme === "light" ? "" : "-dark"}`}>
        <Col className="cdn-blk col-6" md="10" sm="10" lg="10" style={{ textAlign: "center", marginTop: '1em'}}>
          <Image className="img-fluid right-img liveStatsStarImg" src={theme === "light" ? Temperature : TemperatureDark} />
          <br></br>
          <h5 className={`cdn-blk-text${theme === "light" ? "" : " dark"} live-value`}
          id={`confer_temp-${id}`}
          style={{marginTop: '0.3em'}}>{data.selected_kw && data.selected_kw.supply_temp}</h5>
          <h5 className="text-be-float-div">
            <span className={`text-be-float${theme === "light" ? "" : " dark"}`}><sup>&#176;{data.selected_kw && data.selected_kw.unit}</sup></span>
          </h5>
        </Col>
      </Row>
    </Row>

  )
}

export default waterBlk;
