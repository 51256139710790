import React from "react";
import { Table } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../../assets/images/billing/edit.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/trash.svg";
import { ReactComponent as MailIcon } from "../../assets/images/envelope.svg";
import { connect } from "react-redux";
import moment from "moment";

const DesktopTable = (props) => {
  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";

  return (
    <div className={`user_management_table ${theme}`}>
      <Table hover borderless={true} bordered={false} responsive="xl">
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Email</th>
            <th>Groups</th>
            <th>Created By</th>
            <th>Created</th>
            <th>last Login</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.usersList &&
            props.usersList.map((user) => {
              return (
                <tr key={user.id}>
                  <td class="align-middle">{user.full_name}</td>
                  <td class="align-middle">{user.email}</td>
                  <td class="align-middle">
                    {user.groups[0] && user.groups[0].name}
                  </td>
                  <td class="align-middle">{user.created_by}</td>
                  <td class="align-middle">
                    {" "}
                    {user.date_joined !== "-"
                      ? moment(user.date_joined).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  <td class="align-middle">
                    {user.last_login !== "-"
                      ? moment(user.last_login).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  <td class="align-middle">
                    <EditIcon
                      fill={theme === "light" ? "#000" : "#fff"}
                      width={18}
                      onClick={() => {
                        // props.setIsEditable(true);
                        props.getDetailValue(user);
                        props.handleShowUpdate(user);
                      }}
                    />
                  </td>
                  <td class="align-middle">
                    <TrashIcon
                      fill={theme === "light" ? "#000" : "#fff"}
                      width={16}
                      onClick={() => {
                        props.setSelectUser(user);
                        props.setSelectType("delete");
                        props.handleShowAletBox();
                      }}
                    />
                  </td>
                  <td class="align-middle">
                    <MailIcon
                      fill={theme === "light" ? "#000" : "#fff"}
                      width={20}
                      onClick={() => {
                        props.setSelectUser(user);
                        props.setSelectType("mail_resend");
                        props.handleShowAletBox();
                      }}
                    />
                  </td>
                  <td class="align-middle">
                    <button
                      className={`btn ${
                        user.is_active ? "btn-dark" : "btn-danger"
                      } d-flex justify-content-around align-items-center`}
                      onClick={() => {
                        props.setSelectUser(user);
                        props.setSelectType("active");
                        props.handleShowAletBox();
                      }}
                    >
                      <div>{user.is_active ? "Disable" : "Enable"}</div>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
const mapStateToProps = (state) => ({
  usersList: state.usersList.users_list,
});
export default connect(mapStateToProps)(DesktopTable);
