import {  Modal } from "react-bootstrap";

const LoadingModal = ({ loading }) => {
    return (
        <Modal
          show={loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
    )
}

export default LoadingModal;