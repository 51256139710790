import { Row, Col, Modal, Image } from "react-bootstrap";
// icons
import SpaceIcon from "../../assets/images/live/space.svg";
import SpaceDarkIcon from "../../assets/images/live/space-dark.svg";
import LayersIcon from "../../assets/images/live/layers.svg";
import LayersDarkIcon from "../../assets/images/live/layers-dark.svg";
import CubeIcon from "../../assets/images/live/cube.svg";
import CubeDarkIcon from "../../assets/images/live/cube-dark.svg";

function DashboardWaterModal(props) {
  let theme = localStorage.getItem("theme");
  // console.log('DashboardWaterModal props:', props);

  const SpaceDialogContent = () => {
    return (
      <>
        <Row style={{ margin: "10px" }}>
          {props.spaceBlocks !== undefined ? (
            props.spaceBlocks.map((_, index) => {
              return (
                <Col md="3" sm="6" className="spacer col-6" key={index}>
                  <div
                    className={`rounded space-floor-blk ${
                      props.selectedBlock === _.name &&
                      props.allChosenSpaces.indexOf(props.selectedBlock) < 0 &&
                      "active"
                    } ${
                      props.allChosenSpaces.indexOf(_.name) > -1 && "disabled"
                    }`}
                    disabled={
                      props.allChosenSpaces.indexOf(_.name) > -1
                        ? "disabled"
                        : ""
                    }
                    onClick={props.spaceBlkClicked}
                    onMouseOver={props.addSpaceBlkMouseHover}
                    onMouseLeave={props.addSpaceBlkMouseLeave}
                    data-id={_.name}
                  >
                    <Image
                      className="img-fluid"
                      onMouseOver={props.addSpaceBlkMouseHover}
                      data-id={_.name}
                      src={
                        theme === "light" &&
                        props.selectedBlock === _.name &&
                        props.allChosenSpaces.indexOf(props.selectedBlock) < 0
                          ? CubeDarkIcon
                          : theme === "light" && props.selectedBlock !== _.name
                          ? CubeIcon
                          : CubeIcon
                      }
                    />
                    <p
                      className="space-blk-level"
                      style={{
                        paddingLeft: "10px",
                        display: "inline-block",
                        lineHeight: "0.5em",
                      }}
                    >
                      <b>{_.level}</b>
                    </p>
                    <p
                      className="space-blk-name"
                      data-id={_.name}
                      style={{ lineHeight: "1em", textAlign: "left" }}
                    >
                      {_.name}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <div></div>
          )}
        </Row>
      </>
    );
  };

  return (
    <Modal show={props.showSpaceDialog} onHide={props.handleCloseSpaceDialog}>
      <Modal.Header
        closeButton
        className={`${theme === "light" ? "" : "dark dark-blk"}`}
      >
        <Modal.Title>
          <div className="modal-padding-blk"></div>{" "}
          <div className="modal-title-blk">
            <span className={`${theme === "light" ? "" : "dark"}`}>
              {props.title || "Select"}
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
        <SpaceDialogContent />
      </Modal.Body>
    </Modal>
  );
}
export default DashboardWaterModal;
