import { Row, Col, Modal, Image } from "react-bootstrap";
// Icons
import LiveIcon from "../../assets/images/navigation/live.svg";
import LiveAltIcon from "../../assets/images/navigation/live-alt.svg";
import DataIcon from "../../assets/images/navigation/data.svg";
import DataAltIcon from "../../assets/images/navigation/data-alt.svg";
import BillingIcon from "../../assets/images/navigation/billing.svg";
import BillingAltIcon from "../../assets/images/navigation/billing-alt.svg";
import ResourcesIcon from "../../assets/images/navigation/resources.svg";
import ResourcesAltIcon from "../../assets/images/navigation/resources-alt.svg";
import InsightsIcon from "../../assets/images/navigation/insights.svg";
import InsightsAltIcon from "../../assets/images/navigation/insights-alt.svg";
import NotificationsIcon from "../../assets/images/navigation/notifications.svg";
import NotificationsAltIcon from "../../assets/images/navigation/notifications-alt.svg";
import ContactIcon from "../../assets/images/navigation/contact.svg";
import ContactAltIcon from "../../assets/images/navigation/contact-alt.svg";
import Avatar from "../../assets/images/navigation/avatar.png";
import ChilledWaterSupplyIcon from "../../assets/images/navigation/chilled-water.svg";
import ChilledWaterSupplyAltIcon from "../../assets/images/navigation/chilled-water-alt.svg";

function DashboardWidgetModal(props) {
  let theme = localStorage.getItem("theme");

  console.log("props", props);

  const TilesMenus = () => {
    let menutiles = [
      {
        title: "Live - Kaer Air",
        name: "live",
        icon: LiveIcon,
        icon_alt: LiveAltIcon,
        disabled: !props.liveData.show_space_temp,
      },
      {
        title: "Live - Kaer Water",
        name: "chilled_water_supply",
        icon: ChilledWaterSupplyIcon,
        icon_alt: ChilledWaterSupplyAltIcon,
        disabled: !props.liveData.show_multiple_kaer_water,
      },
      {
        title: "Data",
        name: "data",
        icon: DataIcon,
        icon_alt: DataAltIcon,
      },
      {
        title: "Billing",
        name: "billing",
        icon: BillingIcon,
        icon_alt: BillingAltIcon,
      },
      {
        title: "Resources",
        name: "resource",
        icon: ResourcesIcon,
        icon_alt: ResourcesAltIcon,
      },
      // {
      //   title: "Insights",
      //   name: "insights",
      //   icon: InsightsIcon,
      //   icon_alt: InsightsAltIcon,
      // },
      {
        title: "Notifications",
        name: "notifications",
        icon: NotificationsIcon,
        icon_alt: NotificationsAltIcon,
      },
      {
        title: "Contact",
        name: "contact",
        icon: ContactIcon,
        icon_alt: ContactAltIcon,
      },
      {
        title: "Profile",
        name: "profile",
        icon: Avatar,
        icon_alt: Avatar,
      },
    ];

    let access_billing_module = true;
    let access_billing_module_id = -1;

    if (props.AuthDetail.permissions.indexOf("access_billing_module") < 0) {
      access_billing_module = false;
    }

    if (access_billing_module == false) {
      for (let xx = 0; xx < menutiles.length; xx++) {
        if (menutiles[xx]["name"] == "billing") {
          access_billing_module_id = xx;
        }
      }

      menutiles.splice(access_billing_module_id, 1);
    }

    return (
      <Row>
        {menutiles.length > 0 ? (
          menutiles.map((_, index) => {
            return !_.disabled ? (
              <Col md="3" sm="6" className="add-tile-modal col-6" key={index}>
                <div
                  className={`rounded add-tile-modal-child${
                    props.selected_tile === _.name ? " selected" : ""
                  }`}
                  onClick={props.menuClicked}
                  onMouseOver={props.addTileMouseHover}
                  onMouseLeave={props.addTileMouseLeave}
                  data-menu={_.name}
                >
                  <div
                    className="add-tile-img-holder"
                    onMouseOver={props.addTileMouseHover}
                    data-menu={_.name}
                  >
                    <Image
                      onClick={props.menuClicked}
                      onMouseOver={props.addTileMouseHover}
                      data-menu={_.name}
                      className="navigation-icon"
                      src={
                        theme === "light" && props.selected_tile !== _.name
                          ? _.icon
                          : theme === "light" && props.selected_tile === _.name
                          ? _.icon_alt
                          : theme === "dark" && props.selected_tile !== _.name
                          ? _.icon_alt
                          : _.icon_alt
                      }
                    />
                    <h6
                      className="tile-menu-title"
                      onMouseOver={props.addTileMouseHover}
                      data-menu={_.name}
                    >
                      {_.title}
                    </h6>
                  </div>
                </div>
              </Col>
            ) : null;
          })
        ) : (
          <div></div>
        )}
      </Row>
    );
  };

  return (
    <Modal
      show={props.showTile}
      onHide={function () {
        props.setShowTile(false);
      }}
    >
      <Modal.Header
        closeButton
        className={`${theme === "light" ? "" : "dark dark-blk"}`}
      >
        <Modal.Title>
          <div className="modal-padding-blk"></div>{" "}
          <div className="modal-title-blk">
            <span className={`${theme === "light" ? "" : "dark"}`}>
              Add new widget
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${theme === "light" ? "" : "dark dark-blk"}`}>
        <TilesMenus />
      </Modal.Body>
    </Modal>
  );
}
export default DashboardWidgetModal;
